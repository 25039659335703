
export const descIcon = {
    1 : {icon: "icon-icons-01"},
    2 : {icon: "icon-icons-02"},
    3 : {icon: "icon-icons-03"},
    4 : {icon: "icon-icons-04"},
    5 : {icon: "icon-icons-03"},

    105 : {icon: "image_about_planet", text: 'Оптимален для активных интернет-пользователей'},
    106 : {icon: "image_about_planet", text: 'Безопасные платежи в интернете'},
    107 : {icon: "image_about_point", text: 'Функция "Родительский контроль"'},
    108 : {icon: "image_about_lock", text: 'Шифрование файлов и управление паролями'},

    114 : {icon: "image_about_planet", text: 'Быстрый проактивный антивирус'},
    116 : {icon: "image_about_lock", text: 'Защищает от интернет-угроз'},
    117 : {icon: "image_about_point", text: 'Помогает контролировать время использования устройства'},
    118 : {icon: "image_about_planet", text: 'Позволяет узнать местонахождение устройства'},
    120 : {icon: "image_about_planet", text: 'Анти-баннер'},

    121 : {icon: "image_about_lock", text: 'Контроль приложений и времени'},
    122 : {icon: "image_about_planet", text: 'Определение местоположения'},
    123 : {icon: "image_about_point", text: 'Сообщение ребенку'},
    128 : {icon: "image_about_planet", text: 'Обнаруживает во входящих звонках неизвестные номера (интернет-соединение не требуется)'},
    129 : {icon: "image_about_point", text: 'Предоставляет подробные сведения о неизвестных номерах'},
    130 : {icon: "image_about_lock", text: 'Блокирует нежелательные звонки и сообщает информацию о них'},

    132 : {icon: "image_about_point", text: 'Непрерывно повышает точность обнаружения спама за счет обновления антиспам-баз'},
    133 : {icon: "image_about_planet", text: 'Работы выполняются удаленно'},
    138 : {icon: "image_about_lock", text: 'Облачная защита'},
    139 : {icon: "image_about_planet", text: 'Технология безопасного соединения (Kaspersky Secure Connection)'},
    140 : {icon: "image_about_lock", text: 'Резервное копирование'},

    141 : {icon: "image_about_lock", text: 'Менеджер паролей'},
    142 : {icon: "image_about_lock", text: 'Программа "Safe Kids"'},
    143 : {icon: "image_about_lock", text: 'Защита конфиденциальности'},

    151 : {icon: "image_about_lock", text: 'Контроль состояния ПК'},
    153 : {icon: "image_about_lock", text: 'Очистка системы'},
    154 : {icon: "image_about_planet", text: 'Чат с технической поддержкой онлайн'},
    157 : {icon: "image_about_point", text: 'Разовое обращение'},

    161 : {icon: "icon-icons-15", text: 'Антивирус в режиме реального времени'},
    162 : {icon: "icon-icons-26", text: 'Интернет-защита'},
    163 : {icon: "icon-icons-49", text: 'Восстановление зараженного компьютера'},
    164 : {icon: "icon-icons-20", text: 'Анти-Фишинг'},
    165 : {icon: "icon-icons-39", text: 'Защита от шифровальщиков'},
    166 : {icon: "icon-icons-24", text: 'Сетевой экран и Защита от сетевых атак'},
    167 : {icon: "icon-icons-06", text: 'Инструменты оптимизации'},
    168 : {icon: "icon-icons-25", text: 'Освобождение места на устройстве'},
    169 : {icon: "icon-icons-40", text: 'Игровой режим и режим Не беспокоить'},

    170 : {icon: "icon-icons-48", text: 'Управление приложениями'},
    171 : {icon: "icon-icons-46", text: 'Обнаружение сталкерских приложений'},
    172 : {icon: "icon-icons-23", text: 'Контроль камеры и микрофона'},
    173 : {icon: "icon-icons-29", text: 'Поиск небезопасных настроек'},
    174 : {icon: "icon-icons-43", text: 'Удаление неиспользуемых приложений'},
    175 : {icon: "icon-icons-47", text: 'Поиск утечки данных'},
    176 : {icon: "icon-icons-45", text: 'Приватность в социальных сетях'},
    177 : {icon: "icon-icons-16", text: 'Поиск устройства'},
    178 : {icon: "icon-icons-46", text: 'Защита от криптоугроз'},
    179 : {icon: "icon-icons-50", text: 'Премиум-версия Kaspersky Password Manager'},

    180 : {icon: "icon-icons-21", text: 'Защита от звонков спамеров и мошенников'},
    182 : {icon: "icon-icons-51", text: 'Проверка безопасности паролей'},
    183 : {icon: "icon-icons-29", text: 'Хранилище документов'},
    184 : {icon: "icon-icons-20", text: 'Обнаружение удаленного доступа'},
    185 : {icon: "icon-icons-17", text: 'Расширенная техподдержка'},
    186 : {icon: "icon-icons-14", text: 'Приоритетная линия техподдержки'},
    187 : {icon: "icon-icons-13", text: 'Безопасные платежи'},

    191 : {icon: "icon-icons-15", text: 'Антивирусная защита в режиме реального времени'},
    192 : {icon: "icon-icons-13", text: 'Защита платежей'},
    193 : {icon: "icon-icons-06", text: 'Оптимизация производительности'},
    194 : {icon: "icon-icons-19", text: 'Определитель номера Who Calls'},
    195 : {icon: "icon-icons-19", text: 'Поиск утечек данных'},
}
