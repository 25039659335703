import React, {useState} from 'react'
import {descIcon} from './textData'
import {urlGuidSearch, showOsNoBlock, ajaxUpdate} from 'helper-func'
import {req, apiUrl, apiLinkGuidCheckTT} from 'api-methods'

function ULink() {

    // 1. Start Params
    let guid                            = urlGuidSearch(2)
    let ajPost
    let cycleTmpOs                      = ''
    let osArrUpperCase                  = ['Windows','Android','macOS','Linux','iOS']
    let osArr                           = ['windows','android','macos','linux','ios']
    let iconArr                         = ['image_os_w', 'image_os_a', 'image_os_m', 'image_os_l', 'image_os_m']
    let osDistrArr                      = { DISTRLINK: 'Windows', WINLINK32: 'Windows', WINLINK64: 'Windows', MACLINK: 'macOS', IOSLINK: 'iOS', ANDLINK: 'Android', LINLINK32: 'Linux', LINLINK64: 'Linux' }
    let apiAnswer                       = {notFound: {code: 404, message: 'К сожалению, ссылка устарела или не найдена'}, internal: {code: 500, message: 'Внутренняя ошибка'},}

    const KasperLink                    = (props = '') => {return <a target="_blank" href={((props.props !== "") ? props.props : "https://my.kaspersky.com/MyLicenses?&_ga=2.145751539.619871447.1691475332-1962618421.1682680337#/")}>My&nbsp;Kaspersky</a>}
    const getTime = () => {             return (new Date).getTime()}
    const getTimeLock = () => {         return getTime() + 200}
    const [timeLock, setTimeLock]       = useState(getTime())
    const [loader, setLoader]           = useState(true)
    const [reqData, setReqData]         = useState(false)
    const [pageContent, setPageContent] = useState({})
    const [copyPush, setCopyPush]       = useState('')
    const [emailText, setEmailText]     = useState('')
    const [showPopup, setShowPopup]     = useState(false)

    // 3. getData
    async function ajaxGetData () {
        if (guid !== '') {
            ajPost = await req({method: apiUrl.tData, apiRule: 'bang', rType: 'GET', noSearch: false, gets: {guid: guid}})
            apiLinkGuidCheckTT(ajPost)
            if (ajPost.code === 0 && ajPost.data) {
                let treeProp = 0
                let extProp = []
                Object.keys(ajPost.data).forEach((itemOne, indexOne) => {
                    if (indexOne === 0) {
                        ajPost.data[itemOne].ext_prop.forEach((item, i) => {if (parseInt(item) < 100) {extProp.push(item)} else if (showOsNoBlock() && treeProp < 3 && !!descIcon[parseInt(item)]) {treeProp++;extProp.push(item)}})
                        ajPost.data[itemOne].ext_prop = extProp
                        !!ajPost.data[itemOne].distrList && ajPost.data[itemOne].distrList.sort((prev, next) => {if ( prev.key < next.key ) { return -1; }if ( prev.key < next.key ) { return 1; }return 0})
                        setPageContent({code: 0, data: ajPost.data[itemOne]})
                    }
                })
            } else {
                setPageContent(apiAnswer.notFound)
            }
            setLoader(false)
        } else {
            setPageContent(apiAnswer.notFound)
        }
        setTimeLock(getTimeLock())
    }

    // 4. main action
    if (loader) {if (timeLock <= getTime()) {if (!reqData) {setReqData(true);ajaxGetData()}setTimeLock(getTimeLock())}}

    function productNameTrim (name = '') {
        if (!!name.split(')') && name.split(')').length > 2) {name = name.split(')')[0] + ')'}
        if (name.split(' устройств').length === 2) {name = name.split(' устройств').join('&nbsp;устройств')}
        name = name.split('.')[0]
        return name
    }

    function productHtmlTrim (name = '') {
        name = name.split('<p>').join('').split('</p>').join('')
        name = name.split('<b>').join('').split('</b>').join('')
        return name
    }

    function copyTextFromInput(text) {navigator.clipboard.writeText(text);copyKeyPush()}

    function productOs(name){
        let outValue = 0
        osArr.forEach((item, index) => {if (name.toLowerCase().indexOf(item) > -1 && outValue === 0) {outValue = index}})
        return outValue
    }

    function copyKeyPush () {setCopyPush('pageCode__copyPush');setTimeout(() => {setCopyPush('')}, 2000)}

    async function sendLinkToEmail () {
        let userEmail = document.getElementById('userEmail').value
        if (!String(userEmail).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setEmailText('Некорректный E-mail')
        } else {
            setEmailText('Письмо отправляется..')
            document.getElementById('userEmail').value = ''
            ajPost = await req({method: apiUrl.tMail, apiRule: 'bang', rType: 'GET', noSearch: false, gets: {guid: guid, email: userEmail}})
            if (ajPost.code + '' !== '0') {setEmailText('Письмо не отправлено, попробуйте позже')} else {setEmailText('');setShowPopup(true)}
        }
        setTimeout(() => {setEmailText('')}, 5000)
    }

    // 1.4. clear, resize, ajaxUpdate ↓
    if (document.location.hash === '#postmessage') {
        const [resizeValue, setResizeValue] = useState(800)
        function heightSend () {
            let actualPageSize = document.body.clientHeight + 40
            if (resizeValue !== actualPageSize) {
                setResizeValue(actualPageSize)
                window.parent.postMessage({height: actualPageSize}, '*')
            }
        }
        const [resizeEvent, setResizeEvent] = useState(false)
        if (!resizeEvent) {
            setResizeEvent(true)
            window.addEventListener('resize', function(event) {heightSend()}, true)
        }
        setTimeout(() => {heightSend()}, ajaxUpdate)
    }
    // 1.4. clear, resize, ajaxUpdate ↑

    return (
        <div id="page">
            {showPopup && <div className="pagePopup">
                <div className="pagePopupContent">
                    <div className="pagePopupWindow">
                        <div>Отправка письма</div>
                        <div>На указанный вами адрес электронной почты отправлено письмо с подробной информацией о подписке</div>
                        <div className="pagePopupContent__button" onClick={e => setShowPopup(false)}>Хорошо</div>
                    </div>
                </div>
            </div>}
            <div className="pageWidth">
                {loader ? <div className="preLoader"><div className="loader" /></div> : <div>
                    <div className="pageHeader"><a href="https://letai.ru/" className="image_logo_letay" /><div><a href="https://letai.ru/" className="pageHeader__mainSite"><div>Основной сайт</div><div className="image_main_site" /></a></div></div>
                    {pageContent.code !== 0 ? <div className="notFound"><div>{pageContent.code}</div><div>{pageContent.message && pageContent.message}</div></div> : <div>
                        {(pageContent.data.productName) && <div className="pageBanner">
                            <div className="pageBanner__contentBlock">
                                <div className="pageBanner__contentBlock-text">
                                    <div className="pageBanner__contentBlock-textBlock">
                                        <div className="pageBanner__contentBlock-textBlockName" dangerouslySetInnerHTML={{ __html: productNameTrim(pageContent.data.productName) }} />
                                        {pageContent.data.announce !== '' && <div className="pageBanner__contentBlock-textBlockTitle" dangerouslySetInnerHTML={{ __html: productHtmlTrim(pageContent.data.announce) }} />}
                                    </div>
                                    <a href="#activate" className="pageBanner__contentBlock-button">Активировать</a>
                                    <a href="#download" className="pageBanner__contentBlock-button">Скачать</a>
                                </div>
                                <div className="pageBanner__contentBlock-image">{pageContent.data.cover ? <img src={pageContent.data.cover} /> : ''}</div>
                            </div>
                            <div className="waves_block1"><div className="waves"><div className="wave1" /><div className="wave2" /><div className="wave3" /></div></div>
                        </div>}
                        {((pageContent.data.productName).toLowerCase().indexOf('safe kids') === -1)
                            ? <div className="pageAboutService">
                                <div className="pageTitle">Об услуге</div>
                                <div className="pageAboutService__item">
                                    {pageContent.data.ext_prop.map((item, index) => {
                                        if (showOsNoBlock() && !!descIcon[parseInt(item)]) {
                                            return <div key={index}><div className={descIcon[parseInt(item)].icon} /><div>{descIcon[parseInt(item)].text}</div></div>
                                        }
                                        return null
                                    })}
                                </div>
                            </div>
                            : <div className="pageLinkInstruction">
                                <div className="pageTitle" id="help">Инструкция по установке и активации Safe&nbsp;Kids</div>
                                <ul>
                                    <li>Установите приложение на родительское устройство используя ссылки ниже. Рекомендуется установить на смартфон, в виду наличия полного функционала. Устройства на Windows и MacOS предлагают ограниченную функциональность для родителей.</li>
                                    <li>Войдите в учётную запись <KasperLink props={pageContent.data.registrationLink} /> или создайте её.</li>
                                    <li>Установите приложение на устройство ребёнка и следуйте инструкции по настройке.</li>
                                    <li>Войдите в учётную запись <KasperLink props={pageContent.data.registrationLink} /> на телефоне ребёнка. Важно использовать тот же самый аккаунт <KasperLink props={pageContent.data.registrationLink} /> для входа в систему, что и на телефоне родителя, так как во время установки вы синхронизируете свое устройство с устройством ребенка именно через аккаунт <KasperLink props={pageContent.data.registrationLink} />.</li>
                                    <li>Код активации находится ниже. Введите его на странице <KasperLink props={pageContent.data.registrationLink} />.</li>
                                </ul>
                                <p>Для устройств на базе Android код активации можно ввести в самом приложении Safe Kids на вкладке Ещё в правом нижнем углу.</p>
                            </div>}
                        {(pageContent.data.keyList[0].value) && <div className="pageCode">
                            <div className="pageTitle" id="activate">Код активации</div>
                            <div className="pageCode__codeBlock">
                                <div className="pageCode__code">{pageContent.data.keyList[0].value}</div>
                                <div className="pageCode__button image_key_copy" onClick={() => copyTextFromInput(pageContent.data.keyList[0].value)} />
                                <div className={'pageCode__copy ' + copyPush}><div className="image__copyMessage" /><div>код скопирован</div></div>
                                <div className="pageCode__input"><input id="keyActivation" value={pageContent.data.keyList[0].value} onChange={e => {}} /></div>
                            </div>
                        </div>}
                        <div className="pageLink">
                            <div className="pageLink__textBlock">
                                <div>Ссылка на скачивание</div>
                                <div>Чтобы отправить ссылку себе на электронную почту, воспользуйтесь формой ниже: </div>
                                <form onSubmit={e => {false}}>
                                    <input id="userEmail" type="text" placeholder="Введите электронную почту" />
                                    <div onClick={e => sendLinkToEmail()}>Отправить</div>
                                </form>
                                <div>{emailText}</div>
                            </div>
                            <div className="waves_block2"><div className="waves"><div className="wave4" /><div className="wave5" /></div></div>
                        </div>
                        {(pageContent.data.distrList.length > 0) && <div className="pageHelp">
                            <div className="pageTitle" id="download">Скачать</div>
                            <div className="pageHelp__item">
                                {pageContent.data.distrList.map((item, index) => {
                                    cycleTmpOs = productOs(osDistrArr[item.key])
                                    return <div key={index}>
                                        <div className={iconArr[cycleTmpOs]} />
                                        <div>{osArrUpperCase[cycleTmpOs]}</div>
                                        <div dangerouslySetInnerHTML={{ __html: ('Ссылка на скачивание ' + item.name.split(' 1')[0].split(' for')[0]) }} />
                                        <a target="_blank" href={item.value}><div>Скачать</div></a>
                                    </div>
                                })}
                            </div>
                        </div>}
                    </div>}
                </div>}
            </div>
        </div>
    )
}

export default ULink
