import React from "react"
import {Routes, Route} from "react-router-dom"
import ULink from "./uLink"
import "./App.css"
import {urlAlert} from "api-methods"

function App() {

    //if (document.location.pathname.replace(/[/_A-Za-z]+/g, "") !== "") {urlAlert('url-pathname-char').then()}
    //if (document.location.search.replace(/[?=&A-Za-z0-9]+/g, "") !== "") {urlAlert('url-search-char').then()}

    return <Routes>
        <Route path="/*" element={<ULink />} />
        <Route path="/" element={<ULink />} />
    </Routes>
}

export default App
